document.addEventListener('DOMContentLoaded', function() {
  const menuConfigs = [
    {
      buttonSelector: '.header__menu-section',
      menuSelector: '.header__menu-sections-mobile',
      activeClass: 'header__menu-section_active',
      activeMenuClass: 'header__menu-sections-mobile_active'
    },
    {
      buttonSelector: '.header_contacts__menu-section',
      menuSelector: '.header_contacts__menu-sections-mobile',
      activeClass: 'header_contacts__menu-section_active',
      activeMenuClass: 'header_contacts__menu-sections-mobile_active'
    },
    {
      buttonSelector: '.header_blog__menu-section',
      menuSelector: '.header_blog__menu-sections-mobile',
      activeClass: 'header_blog__menu-section_active',
      activeMenuClass: 'header_blog__menu-sections-mobile_active'
    },
    {
      buttonSelector: '.header_compliance__menu-section',
      menuSelector: '.header_compliance__menu-sections-mobile',
      activeClass: 'header_compliance__menu-section_active',
      activeMenuClass: 'header_compliance__menu-sections-mobile_active'
    }
  ];

  menuConfigs.forEach(config => {
    const menuButtons = document.querySelectorAll(config.buttonSelector);
    const menus = document.querySelectorAll(config.menuSelector);

    menuButtons.forEach((menuButton, index) => {
      if (menus[index]) {
        menuButton.addEventListener('click', function() {
          menuButton.classList.toggle(config.activeClass);
          menus[index].classList.toggle(config.activeMenuClass);
        });
      }
    });
  });
});





document.addEventListener('DOMContentLoaded', function() {
  const dropdownLinks = document.querySelectorAll('.mobile-dropdown__link-and-arrow');

  dropdownLinks.forEach(link => {
    link.addEventListener('click', function(event) {

      const arrow = link.querySelector('.mobile-dropdown__arrow');
      if (arrow) {
        arrow.classList.toggle('mobile-dropdown__arrow_active');
      }

      const sections = link.nextElementSibling;
      if (sections && sections.classList.contains('mobile-dropdown__sections')) {
        sections.classList.toggle('mobile-dropdown__sections_visible');
      }
    });
  });
});
